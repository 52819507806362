import { css } from '@emotion/core';
import { variables, mq } from '../../../cssInJs';

const numberOfTiles = [1, 2, 3, 3]
//const marginLR = [14, 14, 14, 14]
const marginTB = [14, 14, 14, 14]

const widthPercent = (index) => 100 / numberOfTiles[index] + '%'

const linkText = {
  color: variables.fontColor,
  fontSize: '0.8rem',
  //padding: '0 20px',
  '&:hover': {
    //color: variables.fontColor,
  }
}

export const tileListStyle = {
  tiles: mq({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    //marginLeft: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    //marginRight: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    marginTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    marginBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    //maxWidth: ['800px'],
    margin: 'auto'
  }),
  categoryList: mq({
    marginTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    marginBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    //maxWidth: ['800px'],
    margin: 'auto',
    textAlign : 'center',
    'div.ff.form-group': {
      //maxWidth: '250px'
      '.ff__input' : {
        display: 'inline-block',
        marginLeft: '1rem'
      }
    }
  })
}

export const tileStyle = {
  tile: mq({
    //paddingLeft: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    //paddingRight: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    
    
    // paddingTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    // paddingBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],

    padding : '0.5rem',
    
    maxWidth: [widthPercent(0), widthPercent(1), widthPercent(2), widthPercent(3)],
    flexBasis: [widthPercent(0), widthPercent(1), widthPercent(2), widthPercent(3)],
    flexGrow: 0,
    flexShrink: 0,
    marginTop : '0.5rem',
    marginBottom : '0.5rem',
    
    //boxShadow: '10px 5px 5px rgba(0,0,0,0.5)'
  }),
  tileLink: {
    background: 'transparent',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    cursor: 'pointer',
    //padding: 5,
    //border: '1px solid red',
    '&:hover': {
      textDecoration: 'none',
      '.product__tile__bg' : {
        transform: 'scale(1.05)',
      },
      '.btn.btn-primary' : {
        backgroundColor : variables.colorDarkGreen
      }
      //background: '#f3f3f3'
    }
  },
  container : {
    boxShadow: '0.5px 1px 2px 0 rgba(0,0,0,.25)',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '15px',
    paddingBottom: '20px',
    height : '100%',
    display : 'flex',
    flexDirection : 'column'
  },
  prdImage: {
    //padding: '0 5px',
    //maxWidth: '190px',
    //margin: 'auto',
    //width: '100%',
    //backgroundColor: 'grey'
    overflow : 'hidden'
  },
  bg: mq({
    //height: 0,
    //paddingBottom: '100%',
    paddingTop: ['100%'],
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    //borderRadius: '47px',
    //boxShadow: '8px 8px 5px rgba(0, 0, 0, 0.04)',
    transition: 'all 0.3s ease-in-out',
    backgroundColor : '#f7f7f7'
  }),
  // shortDes: css({
  //   textAlign: 'center',
  //   marginTop: '0.8rem'
  // }),
  name: css(linkText, mq({
    //fontWeight: '700',
    //fontFamily: variables.familyHeader,
    //textAlign: 'center',
    //marginBottom: '1rem',
    marginTop : '1rem',
    textDecoration: 'none',
    lineHeight : '1.1',
    fontSize : ['1.5rem',null,'27px'],
    fontWeight: ['700']
  })),
  des: css(linkText, mq({
    textDecoration: 'none',
    lineHeight : '1.1',
    fontSize : ['1rem',null,'1rem'],
    marginTop : '0.5rem',
    flex: '1 0 auto'
  })),
  price: css(linkText, mq({
    marginTop: '0.2rem',
    textAlign: 'center',
    //marginBottom: '2rem',
    textDecoration: 'none',
    fontSize : ['1rem',null,'0.8rem'],
    //fontWeight: ['700']
  })),

  button: css(linkText, mq({
    //textDecoration: 'none',
    //fontSize : ['1rem',null,'1rem'],
    marginTop : '0.3rem',
    'button' : {
      fontWeight: '600',
      padding : '10px 30px',
      fontSize : '18px',
      lineHeight: '1',
      letterSpacing: '1px'
    }
  })),

  new : {
    position: 'absolute',
    top: '10px',
    right: '10px',
    borderRadius: '50%',
    height: '55px',
    width : '55px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight : '600',
    fontSize : '16px',
    color : 'white',
    backgroundColor : 'rgba(134, 188, 68, 0.6)'
  },

  sale : {
    position: 'absolute',
    top: '10px',
    right: '10px',
    borderRadius: '50%',
    height: '55px',
    width : '55px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight : '600',
    fontSize : '16px',
    color : 'white',
    backgroundColor : 'rgba(255, 0, 0, 0.6)'
  },

  portal__background :{
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: 9998
  },

  portal__popupcontainer : mq({
    position: 'fixed',
    top: '50%',
    left : '50%',
    width: '100%',
    zIndex : '9999',
    transform : 'translate(-50%, -50%)',
    maxWidth: ['95%',null,'800px']
  }),

  related__product__head : {
    
    textAlign : 'center',
    backgroundColor : 'black',
    position : 'relative',

    'img' : mq({
      width : 'auto',
      height : ['32px',null,'45px']
    }),

    svg : {
      marginTop : '-5px'
    }
  },

  related__product__img : {
    minHeight: '400px',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor : 'white',
    'img' : mq({
      //width : 'auto',
      maxWidth : '100%',
      height: 'auto',
      position : 'absolute',
      //top: '50%',
      left: '50%',
      transform : 'translateX(-50%)',
      paddingTop : ['1rem',null,'1rem']
    })
  },

  related__product__title : mq({
    fontSize:['1.3rem',null,'1.5rem'],
    textAlign: 'left',
    marginTop:['0.5rem',null,'0.5rem'],
    marginBottom:'1rem'
  }),

  related__product__contents : mq({
    marginLeft : '0px',
    marginRight: '0px',
    maxHeight: ['500px',null,'640px'],
    overflow: 'auto'
  }),

  related__product__desc: {
    fontWeight : '300',
    'p': {
      fontSize: '1rem !important',
      fontFamily: variables.familyBase + ' !important',
      marginBottom: '0.5rem !important',
      lineHeight: '1.1 !important'
    }
  },


}


// div.portal__popupcontainer {
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   width: 100%;
//   z-index: 9999;
//   transform: translate(-50%, -50%);
//   max-width: 920px;
  
//   background-color: white;
  
// }
//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

//import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs';

export default {
    pageTiles__big: mq({
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        margin: '2rem -10px',
        marginLeft : ["-10px", "-20px", "-30px", "-40px", "-50px", "-60px"],
        marginRight : ["-10px", "-20px", "-30px", "-40px", "-50px", "-60px"],
        overflow: 'hidden'
    }),
    pageTile__big: mq({
        flex: ['1 0 100%',null, '1 0 50%', '1 0 25%'],
        maxWidth:['100%', null, '50%', '25%'],
        padding: '10px',
        paddingLeft: '1rem',
        paddingRight: '1rem'
    }),
    pageTile__big__content : {
        '&:hover' : {
            textDecoration: 'none',
            '.pageTile__big__bg' : {
                transform : 'scale(1.05)'
            }
        }
    },
    pageTile__big__wrapper : {
        //boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.25)',
        boxShadow: '0.5px 1px 2px 0 rgba(0,0,0,.25)',
        padding : '0.4rem',
        overflow: 'hidden',
        display : 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        height: '100%'
    },
    pageTile__big__bg : {
        paddingTop: '65.25%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        transition:'all .2s'
    },
    pageTile__big__title : {
        textAlign: 'center',
        fontSize: '1.3rem',
        color: variables.fontColor,
        marginTop: '0.5rem',
        lineHeight: '1',
        flex : '1 0 auto',
        maxWidth : '90%',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    pageTile__big__middle__text : {
        color: '#767676',
        textAlign: 'center',
        marginTop: '0.5rem',
        fontSize : '0.7rem',
        fontWeight : '600'
    },
    pageTile__big__button : {
        textAlign: 'center',
        marginTop: '1rem',
        marginBottom: '1.4rem',
        'button' : {
            fontWeight: '600',
            padding : '10px 24px',
            fontSize : '0.8rem',
            lineHeight: '1',
            letterSpacing: '1px'
        }
        // 'button' : {
        //     backgroundColor: variables.colorGreen,
        //     color : 'white',
        //     borderColor: variables.colorGreen
        // }
    }
}
import React from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
//import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  
  const links = [
    { url: '/products', title: 'PRODUCTS', icon: 'products.png', iconHover: 'products.png' },
    { url: '/services', title: 'SERVICES', icon: 'services.png', iconHover: 'services.png' },
    { url: '/about', title: 'ABOUT', icon: 'about.png', iconHover: 'about.png' },
    { url: '#contact', title: 'CONTACT', icon: 'contact.png', iconHover: 'contact.png' },
    { url: '/information', title: 'NEWS & BLOG', icon: 'information.png', iconHover: 'information.png' }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        <div className="icon">
          <img className="normal" src={`/assets/navicons/${link.icon}`} alt={link.title} />
          <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  );

  return (
    <>
      <nav className="bottomMenu">
        <hr />
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
    </>
  );
}
import React, { useState, useEffect } from 'react';
import { useRouter } from '../../../components';
import { useGet } from '../../../components';
import { Item } from '../../items/item';
import { NotFound } from '../../../components';
import env from '../../../env';
import { useStore } from '../../../store';
import { ErrorPopup, Loading } from '../../../components';
import { useInstagram } from '../../../components';
import { MetaTag } from '../../../components';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { gtm } from '../../../lib/tracking';
import { Helmet } from 'react-helmet-async';

const log = (...args) => {
  //console.log(args);
}
const areEqual = (prevProps, nextProps) => {
  return (prevProps.pageData === nextProps.pageData)
};
const PageMemo = React.memo(Page, areEqual)

export function ViewPage() {
  const { dispatch } = useStore();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [data, setData] = useState(null);
  //const path = usePath();
  //log('view page', path);
  const { pathname, Redirect } = useRouter();
  useInstagram();

  const get = useGet();
  log('0. viewpage ready');

  useEffect(() => {
    log('*. start loading')
    setPageLoaded(false);
    get.send(env.apiBase + "/api/cms/GetPublishedPage?path=" + pathname);
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (data){
      dispatch({ type: 'SET_PAGE', payload: data.data })
      dispatch({ type: 'SET_MENU', payload: data.menu })
    }
    // eslint-disable-next-line
  }, [data]);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  //log(router.pathname);
  //log(get.loading());
  log('1. viewpage component', pageLoaded, get.response);


  const pageData = get.response.data;
  //const menuData = get.response.menu;
  const redirectTo = get.response.redirectTo;

  const cmsOption = {};
  log(pageLoaded, !pageLoaded && !!pageData)
  if (!pageLoaded && pageData) {
    //log(pageLoaded, pageData, menuData)
    log('2. dispatching page & menu')
    setPageLoaded(true);
    setData(get.response);
    document.title = pageData.pageTitle;
    gtm.pageView(pathname, pageData.pageTitle);
    return null;
  } else if (redirectTo) {
    if (redirectTo.indexOf('http') >= 0 && typeof window !== `undefined`) {
      return window.location.href = redirectTo;
    } else {
      return <Redirect to={redirectTo} />
    }
  } else if (!pageLoaded && !pageData) {
    setPageLoaded(true);
    setData(get.response);
    return (<NotFound />)
  }

  log('3. call rendering memo')
  if (pageData)
  return <PageMemo pageData={pageData} cmsOption={cmsOption} />;
  else
    return <NotFound />
}


function Page(props) {
  const pageData = props.pageData;
  const cmsOption = props.cmsOption;
  cmsOption.pageData = pageData;
  // document.title = pageData.pageTitle;
  log('4. page rendering', pageData, cmsOption)
  const sectionItems = pageData.sections.map((section, index) => {
    return (
      <Section item={section} key={section.sectionName + index} cmsOption={cmsOption}></Section>
    )
  }
  );

  const homeStructuredData = {
	  "@context": {
	    "@vocab": "http://schema.org/"
	  },
	  "@graph": [
	    {
	      "@id": "https://www.controlairaustralia.com.au/",
	      "@type": "Organization",
	      "name": "Control Air Australia",
	      "url" : "https://www.controlairaustralia.com.au/",
	      "logo" : "https://www.controlairaustralia.com.au//assets/logos/logo.png",
        "telephone": "1300 693 044",
        "keywords":
            " Commercial refrigerators, Industrial fridges, Commercial Refrigeration, Hussmann Commercial Refrigerators, Refrigeration solutions, Air Conditioning",
            "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "5",
            "reviewCount": "3",
            },
	      },
	      {
	      "@type": "LocalBusiness",
	      "parentOrganization": {
	          "name" : "Control Air Australia"
	      },
        "name" : "Control Air Australia",
        "address": {
            "@type": "PostalAddress",
            "addressLocality": "Newton SA",
            "addressRegion": "SA",
            "postalCode": "5074",
            "streetAddress": "Unit 2, 12-14 Hamilton Terrace",
            "telephone": "(08) 8165 2015",
          },
        "openingHoursSpecification": {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
          ],
        "opens": "00:00",
        "closes": "23:59"
        },
	      "hasmap" : "https://www.google.com/maps/place/Control+Air+Australia+Pty+Ltd/@-34.876602,138.68942,15z/data=!4m6!3m5!1s0x6ab0b56356157825:0x86712bc8bf142bff!8m2!3d-34.8766025!4d138.6894196!16s%2Fg%2F11dx8lhvcg?hl=en&entry=ttu&g_ep=EgoyMDI0MTAwOS4wIKXMDSoASAFQAw%3D%3D"
	    },
      {
	      "@type": "LocalBusiness",
	      "parentOrganization": {
	          "name" : "Control Air Australia"
	      },
        "name" : "Control Air Australia",
        "address": {
            "@type": "PostalAddress",
            "addressLocality": "Canning Vale WA",
            "addressRegion": "WA",
            "postalCode": "6155",
            "streetAddress": "54 Mourdant Circuit",
            "telephone": "(08) 9455 3301",
          },
        "openingHoursSpecification": {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
          ],
        "opens": "00:00",
        "closes": "23:59"
        },
	      "hasmap" : "https://www.google.com/maps/place/Control+Air+Australia/@-32.072811,115.906821,15z/data=!4m6!3m5!1s0x2a329864d070082f:0x1d174f4d126dbb99!8m2!3d-32.072811!4d115.9068211!16s%2Fg%2F11cs4hly7s?hl=en&entry=ttu&g_ep=EgoyMDI0MTAwOS4wIKXMDSoASAFQAw%3D%3D"
	    },
      {
	      "@type": "LocalBusiness",
	      "parentOrganization": {
	          "name" : "Control Air Australia"
	      },
        "name" : "Control Air Australia",
        "address": {
            "@type": "PostalAddress",
            "addressLocality": "Stapylton QLD",
            "addressRegion": "QLD",
            "postalCode": "4207",
            "streetAddress": "10/65 Christensen Rd",
            "telephone": "(07) 3807 3133",
          },
        "openingHoursSpecification": {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
          ],
        "opens": "00:00",
        "closes": "23:59"
        },
	      "hasmap" : ""
	    },
      {
	      "@type": "LocalBusiness",
	      "parentOrganization": {
	          "name" : "Control Air Australia"
	      },
        "name" : "Adapt Refrigeration",
        "address": {
            "@type": "PostalAddress",
            "addressLocality": "Carrum Downs VIC",
            "addressRegion": "VIC",
            "postalCode": "3201",
            "streetAddress": "3/28-30 Silkwood Rise",
            "telephone": "(03) 9775 0141",
          },
        "openingHoursSpecification": {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
          ],
        "opens": "00:00",
        "closes": "23:59"
        },
	      "hasmap" : "https://www.google.com/maps/place/Adapt+Refrigeration+Pty+Ltd/@-38.089889,145.161341,13z/data=!4m6!3m5!1s0x6ad60dfaa4158041:0xf2a7e32f8faaa045!8m2!3d-38.0898886!4d145.1613406!16s%2Fg%2F11rpd2f2lg?hl=en&entry=ttu&g_ep=EgoyMDI0MTAwOS4wIKXMDSoASAFQAw%3D%3D"
	    },
	  ]
	}

  // const homeStructuredData = {
  //   "@context": "https://schema.org",
  //   "@type": "LocalBusiness",
  //   "name": "Control Air Australia Pty Ltd",
  //   "alternateName": "Control Air Australia",
  //   "url": "https://www.controlairaustralia.com.au/",
  //   "logo": "https://www.controlairaustralia.com.au//assets/logos/logo.png",
  //   "telephone": "1300 693 044",
  //   "address": {
  //     "@type": "PostalAddress",
  //     "addressLocality": "Newton SA",
  //     "addressRegion": "SA",
  //     "postalCode": "5074",
  //     "streetAddress": "Unit 2, 12-14 Hamilton Terrace",
  //     "addressCountry": "Australia"
  //   },
  //   "keywords":
  //     " Commercial refrigerators, Industrial fridges, Commercial Refrigeration, Hussmann Commercial Refrigerators, Refrigeration solutions, Air Conditioning",
  //     "aggregateRating": {
  //     "@type": "AggregateRating",
  //     "ratingValue": "5",
  //     "reviewCount": "3",
  //     },
  //   "openingHoursSpecification": {
  //   "@type": "OpeningHoursSpecification",
  //   "dayOfWeek": [
  //     "Monday",
  //     "Tuesday",
  //     "Wednesday",
  //     "Thursday",
  //     "Friday",
  //     "Saturday",
  //     "Sunday"
  //   ],
  //   "opens": "00:00",
  //   "closes": "23:59"
  //   }
  // };

  return (
    <>
      <MetaTag data={getMetaTag(pageData)} />
      {pageData.pageUrl === "/" && (
        <Helmet>
          <script type='application/ld+json'>
            {JSON.stringify(homeStructuredData)}
          </script>
        </Helmet>
      )}
      <div className={`cmsPage cmsPage--${pageData.pageTypeCode}`}>
        {sectionItems}
      </div>
    </>
  );
}

function Section(props) {
  log('5. section rendering')
  const item = props.item;
  //const sectionName = item.sectionName;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  if (!subItems || subItems.length === 0)
    return (<></>);

  const subItemsElts = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );
  return (
    <>
      {subItemsElts}
    </>
  );
}

function getMetaTag(pageData) {
  //const country = utils.site.isNZ? 'New Zealand' : 'Australia'
  const country = "Australia";
  const siteName = env.siteName;
  const titleSuffix = env.titleSuffix;

  return {
    title: pageData.pageTitle + ' | ' + titleSuffix,
    description: cmsUtils.payload(pageData, 'Description'),
    keywords: cmsUtils.payload(pageData, 'Keyword'),
    heroImageUrl: utils.site.fullUrl(cmsUtils.payload(pageData, 'ImageUrl')),
    language: 'English',
    country: country,
    siteName: siteName,
    url: utils.site.fullUrl(pageData.pageUrl),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  }
}
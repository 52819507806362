const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  colorBlue : '#007bff',
  colorDarkGray : '#212121',
  colorGreen : '#8dc63f',
  colorDarkGreen : '#769528',
  colorMidGrey : '#414141',
  colorLightGrey : '#919191'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',

  primaryColor: colors.colorBlue,
  fontColor: colors.colorDarkGray,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment, useEffect, useState } from 'react';
import env from '../../../env';
import { SiteLink } from '../../../components'; //useRouter
//import { useHistory } from "react-router-dom";
import { useForm } from '../../../form';
import { fb, validators } from '../../../form';
import { Button, ErrorMessage } from '../../../components';
import { usePost } from '../../../components';
import utils from '../../../utils';
import style from './productViewStyle';
//import { MdKeyboardArrowDown } from "react-icons/md";
import { useStore } from '../../../store';
//import {ProductAttributes} from './productAttributes';

export function AddToCart({ product, setVariantOptions = null, setEnquiryFormQty = null }) {

  const post = usePost();
  //const { Redirect } = useRouter();
  //const history = useHistory();
  const render = (name, parent = null) => form.renderControl(name, parent);
  const model = getFormModel(product, {});
  const form = useForm(model, { usePlaceholder: true, onControlChanged: onControlChanged });
  const attributes = form.findControl('variantOptions');

  const [priceItem, setPriceItem] = useState(() => {
    return 'AU ' + utils.money.formatMoney(getProductItem());
  });

  const [checkGoToCartBtn, setCheckGoToCartBtn] = useState(false);
  const { dispatch } = useStore();

  function getProductItem() {
    const elss = [];
    if (attributes && attributes.controls.length > 0) {
      attributes.controls.forEach(el => {
        elss.push(el.controls[1].value);
      });
    }

    let _price = 0;

    for (let x in product.variants) {
      let checkVariant = true;
      for (let y in product.variants[x].variantOptions) {
        if (!elss.toString().includes(product.variants[x].variantOptions[y].value)) {
          checkVariant = false;
        }
        if (checkVariant === false) {
          break;
        }
      }

      if (checkVariant === true) {
        _price = product.variants[x].price || 0;
        break;
      }
    }

    let _priceResult = product.priceIncTax + _price;
    return _priceResult;

  }

  function onControlChanged(e, control) {
    //if (!(control.type === 'select' && control.parent)) return;
    //console.log("control.type", control.type, control.parent); 
    if (!(control.parent)) return;

    const formVal = form.getValue();
    if (setVariantOptions !== null) { 
      let _variantOptions = [];
      for (let i in formVal.variantOptions) {
        let _item = {
          name: formVal.variantOptions[i].name,
          value: formVal.variantOptions[i][Object.keys(formVal.variantOptions[i])[1]]
        };
        _variantOptions.push(_item);
      }
      setVariantOptions(_variantOptions);
    }

    if(setEnquiryFormQty !== null){
      setEnquiryFormQty(formVal.quantity);
    }

    setPriceItem('AU ' + utils.money.formatMoney(getProductItem()));
  }

  function addToCart(e) {
    form.validateForm(e, () => {
      const formVal = form.getValue();
      let _variantOptions = [];
      for (let i in formVal.variantOptions) {
        let _item = {
          name: formVal.variantOptions[i].name,
          value: formVal.variantOptions[i][Object.keys(formVal.variantOptions[i])[1]]
        };
        _variantOptions.push(_item);
      }
      //const value = { ...formVal, productId: product.productId, cartId: env.getDevCartId() };
      let _cartId = env.getDevCartId();
      const value = {
        quantity: formVal.quantity,
        variantOptions: _variantOptions,
        productId: product.productId,
        cartId: _cartId === 'null' ? null : _cartId
      };
      //console.log('value', value);
      post.send(env.apiBase + "/api/cart/addToCart", value);
    })
  }

  if (post.done()) {
    env.setDevCartId(post.response.results.cartInfo.cartId);
    //console.log('dfdlsjflskdfj',post.response);
    if (post.response.errors.length < 1) {
      setTimeout(() => {
        dispatch({ type: 'SET_STATE', payload: { cartInfo: post.response.results.cartInfo.totalQuantity === 0 ? 0 : (post.response.results.cartInfo.totalQuantity) - 1 } });
        setCheckGoToCartBtn(true);
      }, 500);
    }

    post.reset();

    // return <Redirect to="/cart" />
  }

  useEffect(() => {
    if (setVariantOptions === null) {
      return;
    }

    const formVal = form.getValue();
    let _variantOptions = [];
    for (let i in formVal.variantOptions) {
      let _item = {
        name: formVal.variantOptions[i].name,
        value: formVal.variantOptions[i][Object.keys(formVal.variantOptions[i])[1]]
      };
      _variantOptions.push(_item);
    }
    setVariantOptions(_variantOptions);

    // eslint-disable-next-line
  }, []);

  return <div style={{ marginBottom: `${product.productStatus === 0 ? '3rem' : '1rem'}` }}>

    {/* <div className="productView__price">
      Price: <b>{product.priceText ? product.priceText : 'AU ' + utils.money.formatMoney(product.price)}</b>
    </div> */}

    {/* <ProductAttributes product={product} /> */}
    <Fragment>
      {attributes.controls.length > 0 && <hr style={{ borderColor: 'black' }} />}
      {
        attributes.controls.map(
          (item, index) => {
            return (
              <div className="row" key={index}>

                <div className="col-sm-12">
                  {item.controls[0].value}
                </div>

                <div css={style.attrContainer} className={`col-sm-12 open`}>
                  {render(item.controls[0].value.toLowerCase(), item)}
                </div>

              </div>
            )
          }
        )
      }

    </Fragment>

    {
      product.productStatus !== 0 &&
      <div className="form-row">
        <div className="qty col-sm-12">
          <div>
            Quantity
          </div>
          {render('quantity')}
        </div>
      </div>
    }

    {
      product.productStatus === 0 &&
      <form onSubmit={addToCart} style={{ textAlign: 'left', marginBottom: '2rem' }}>
        <div className="form-row" style={{ marginTop: '1rem', marginBottom: '0.5rem' }}>
          <div className="col-md-8" css={style.priceText}>
            All prices shown in AUD
          </div>
          <div className="col-md-4">
            <div className="productView__price">
              <b>
                {/* {product.priceTextOveriding ? product.priceTextOveriding : 'AU ' + utils.money.formatMoney(product.priceIncTax)} */}
                {
                  // (product.priceText && product.priceText !== '') ? product.priceText : (product.priceTextOveriding ? product.priceTextOveriding : 'AU ' + utils.money.formatMoney(product.priceIncTax))
                  priceItem
                }
              </b>
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="qty col-md-4">
            {render('quantity')}
          </div>
          <div className="col-md-8" css={style.addToCartBtn}>
            <Button onClick={addToCart} status={form.status}> ADD TO CART </Button>
            {
              checkGoToCartBtn === true &&
              <SiteLink className="btn-primary btn" to="/cart" style={{ marginTop: '0.5rem' }}> GO TO CART </SiteLink>
            }
          </div>
        </div>

        <div>
          {/* <Button onClick={addToCart} status={form.status}>Add to cart</Button> */}
          <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
          <ErrorMessage errors={post.errors} />
        </div>
      </form>
    }




  </div>
}

function getFormModel(product, formValues /* for reset */) {
  const variantAttributes = product.variantAttributes;
  const model = fb.group({
    //quantity: [formValues.quantity || 1, [validators.Required()], { label: 'Quantity', type: 'number' }],
    quantity: [formValues.quantity || 1, [validators.Required()], { label: 'Quantity', type: 'number' }],
    variantOptions: fb.array([]),
  });
  const variantOptionArray = model.find('variantOptions').controls;
  (variantAttributes || []).forEach(attr => {
    variantOptionArray.push(newVariantAttribute(attr, formValues, product.variants))
  })
  return model;
}

function newVariantAttribute(attr, formValues, variants) {

  // const choices = [
  //   { name: 'Yes, please!', value: 'Yes' },
  //   { name: 'No, thanks', value: 'No' }
  // ]

  const dyName = attr.name.toLowerCase();
  const optionsSelected = formValues.variantOptions || [];
  const currentSelection = utils.array.find(optionsSelected, x => attr.name === x.name);

  const filtered = variants.filter(x => matchVariantOptions(optionsSelected, x.variantOptions, attr.name))
  const availableOptions = getAvailableOptionValues(filtered, attr.name);
  const options = attr.options.map(x => { return { ...x, ...{ disabled: !availableOptions.some(o => o === x.value) } } });

  // console.log("options", options);
  // console.log("variants", variants);
  // console.log("attr", attr);
  // console.log("availableOptions", availableOptions);

  return fb.group({
    name: [attr.name, [], { type: 'hidden' }],
    [dyName]: [(currentSelection && currentSelection.value) || attr.value || options[0].value,
    [validators.Required()], {
      //label: attr.name,
      label: null,
      type: 'select',
      //options : choices
      options: [...options].map(x => {
        return {
          name: (x.displayName || x.value) + (x.disabled || false ? ' - not available' : ''),
          value: x.value,
          disabled: x.disabled || false
        }
      })
    }]
  })

}

function getAvailableOptionValues(variants, name) {
  const values = variants.map(x => {
    const options = x.variantOptions.filter(o => o.name === name)
    return options && options[0].value
  }).filter(x => x);

  return Array.from(new Set(values));
}

function matchVariantOptions(optionsSelected, optionsInVariant, currentControlName) {
  //console.log(optionsSelected, optionsInVariant, currentControlName)
  for (let i = 0; i < optionsSelected.length; i++) {
    let optionSelected = optionsSelected[i];
    if (optionSelected.value === '') {
      // skip match test
      continue;
    }
    if (optionSelected.name === currentControlName) {
      // skip match test for the just selected value
      continue;
    }
    let found = false;
    for (let j = 0; j < optionsInVariant.length; j++) {
      let optionInVariant = optionsInVariant[j];
      if (optionSelected.name === optionInVariant.name && optionSelected.value === optionInVariant.value) {
        found = true;
        break;
      }
    }
    if (!found) return false;
  }
  return true;
}
import { css  } from '@emotion/core'
import { mq, variables } from '../../cssInJs'

export default {
  top: css(
    mq({
      //height: [44, null, 80],
      height: [44, null, 56],
      background: "transparent",
      position: "fixed",
      width: "100%",
      zIndex: "999",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "all 0.3s",
    })
  ),
  topScrolled: css(
    mq({
      //height: [44, null, 56],
      background: variables.colorDarkGray
    })
  ),
  logo: css(mq({})),
  logoImg: css(
    mq({
      display: "block",
      height: [46, null, 80],
      marginTop: ["3rem", null, "3rem"],
      transition: "all .2s",
    })
  ),
  logoScrolled: css(
    mq({
      img: css(
        mq({
          height: [30, null, 36],
          marginTop: "0rem",
        })
      ),
    })
  ),

  shopping__cart : mq({
    position : 'absolute',
    right: ['50px', null, '100px'],
    color: 'white',
    cursor: 'pointer',
    'svg' : mq({
      width : '30px',
      height : '30px'
    })
  }),

  shopping__cart__scrolled : {

  },

  shopping__cart__num : mq({
    position : 'absolute',
    display : 'flex',
    width: ['20px',null,'30px'],
    height: ['20px',null,'30px'],
    backgroundColor: 'red',
    borderRadius: '50%',
    justifyContent : 'center',
    alignItems : ['center', 'end'],
    top : ['-3px',null,'-8px'],
    left: ['-9px',null,'-15px']
  })

};
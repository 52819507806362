/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react';
import cmsUtils from '../../cmsUtils';
import utils from '../../../../utils';
import { YoutubeBannerMobilePopup } from '../../../../lib/youtube/youtubeBanner--mobilePopup';
import { getStyle } from './videoPanelStyle';

export function VideoPanel(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';
  const videoCode = cmsUtils.payload(item, 'YoutubeCode');
  
  const overlayColor = cmsUtils.payload(item, 'BgColor') || 'transparent';
  const overlayOpacity = cmsUtils.payload(item, 'OverlayOpacity') || '1.0';
  
  const posterFrameUrl = cmsUtils.payload(item, 'PosterFrameUrl', null);
  const playerId = 'ytBanner-' + (item.itemId).substr(0, 8);

  const video = {
    heightSet: heightSet,
    videoLinkOrId: videoCode,
    playerId: playerId
  }

  //const enableYoutube = env.isProd;
  const canPlayVideo = () => utils.css.screenWidth() >= 1200

  const cssClass = utils.classNames('cms_item', 'youtubePanel', item.cssClass || item.anchorName || '');
  
  const htmlBlock = React.useRef(null);
  const cssClass2 = utils.classNames('cms_item', 'htmlPanel', cmsOption.isCmsEdit && 'html', item.cssClass || item.anchorName || '');
  const content = cmsUtils.payload(item, 'Content');

  const style = getStyle({overlayColor : overlayColor, overlayOpacity : overlayOpacity});
  // css={style.single__banner__bg}

  return (
    <div className={cssClass} style={{ position: 'relative', overflow: "hidden" }} css={style.single__banner__bg}>
      {/* {canPlayVideo() ? <YoutubeBanner video={video} /> : <BannerPanel item={item}></BannerPanel>} */}
      <div ref={htmlBlock} className={cssClass2} data-cms-html="true" data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
        dangerouslySetInnerHTML={{ __html: content }}>
      </div>
      <YoutubeBannerMobilePopup video={video} canPlayVideo={canPlayVideo} posterImageUrl={utils.site.resourcePath(posterFrameUrl)} />
    </div>
  );
}
